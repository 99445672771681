* {
  box-sizing: border-box;
}

html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main-container {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
}

.main-bot-dialog {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
}

/* Format adaptive cards in client */
.ac-choiceSetInput-expanded > div {
  margin-bottom: 6px;
}

/* Format dialogs in client */


/*
@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}
*/